import { ChangeEvent, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import * as yup from "yup";
import { useFormik } from "formik";

import {
  Box,
  Input,
  Select,
  Header,
  TextField,
  Button,
  InputImage,
  Modal,
  ModalContent,
  Checkbox,
} from "common/components";
import provinces from "constants/provinces.json";
import d from "constants/districts.json";
import { IOption } from "types";
import useImage from "hooks/imageHook";

const Schema = yup.object().shape({
  name: yup.string().required("Энэ талбар хоосон байна!"),
});

const AddOrEditBranch = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { state }: { state: any } = useLocation();
  const [districts, setDistricts] = useState<IOption[]>([]);
  const [selectedProvince, setSelectedProvince] = useState<IOption>(
    state?.province && {
      value: state?.province,
      label: state?.province,
    }
  );
  const [selectedDistrict, setSelectedDistrict] = useState<IOption | null>();
  const [timeTableCounter, setTimeTableCounter] = useState(
    state?.timeTables ? state.timeTables.length - 1 : 0
  );
  const [isLoading, setIsLoading] = useState(false);
  const [timeTables, setTimeTables] = useState<{ id: number; value: string }[]>(
    state?.timeTables
      ? state.timeTables.map((item: string, index: number) => ({
          id: index,
          value: item,
        }))
      : []
  );
  const [errorMsg, setErrorMsg] = useState("");
  const [isProject, setIsProject] = useState(!!state?.isProject);
  const [isSum, setIsSum] = useState(!!state?.isSum);
  const image = useImage(state?.image);

  useEffect(() => {
    setSelectedDistrict(null);
    if (selectedProvince) {
      const newD = d.find((i) => i.key === selectedProvince.value);
      setDistricts(newD?.data || []);
    }
  }, [selectedProvince]);

  useEffect(() => {
    if (state?.district) {
      setSelectedDistrict({
        value: state.district,
        label: state.district,
      });
    }
  }, []);

  const hideModal = () => {
    setErrorMsg("");
  };

  const changeSelectedProvince = (data: IOption) => {
    setSelectedProvince(data);
  };

  const changeSelectedDistrict = (data: IOption) => {
    setSelectedDistrict(data);
  };

  const addTimeTable = () => {
    setTimeTables([...timeTables, { id: timeTableCounter + 1, value: "" }]);
    setTimeTableCounter(timeTableCounter + 1);
  };

  const { handleChange, handleBlur, handleSubmit, values, touched, errors } =
    useFormik({
      validationSchema: Schema,
      initialValues: {
        name: state?.name || undefined,
        phone: state?.phone || undefined,
        facebook: state?.facebook || undefined,
        web: state?.web || undefined,
        address: state?.address || undefined,
        youtube: state?.youtube || undefined,
        index: state?.index || undefined,
      },
      onSubmit: () => {
        setIsLoading(true);

        const timeData = timeTables
          .filter((item) => item.value !== "")
          .map((item) => item.value);

        const data = {
          name: values.name,
          imgId: image.image?.id,
          phone: values.phone,
          facebook: values.facebook,
          web: values.web,
          address: values.address,
          youtube: values.youtube,
          province: selectedProvince?.value,
          district: selectedDistrict?.value,
          timeTables: JSON.stringify(timeData),
          index: values.index,
          isProject,
          isSum,
        };

        if (!state) {
          axios
            .post("/branch/create", data)
            .then(() => {
              setIsLoading(false);
              navigate(-1);
            })
            .catch((e) => {
              setErrorMsg("Нэр давхцаж байна!");
              setIsLoading(false);
            });
        } else {
          axios
            .post(`/branch/${state.id}/update`, {
              ...data,
            })
            .then(() => {
              setIsLoading(false);
              navigate(-1);
            })
            .catch((e) => {
              setErrorMsg(e.type);
              setIsLoading(false);
            });
        }
      },
    });

  const renderTimeTable = () => {
    const data = [];
    const clone = [...timeTables];

    for (let i = 0; i < timeTables.length; i++) {
      const onClick = () => {
        const newData = clone.filter((item) => item.id !== clone[i].id);
        setTimeTables(newData);
      };

      const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
        const index = clone.findIndex((item) => item.id === clone[i].id);
        clone[index] = { ...clone[index], value: e.target.value };
        setTimeTables(clone);
      };

      data.push(
        <Box
          display="flex"
          mt={`${theme.spacing.l}em`}
          alignItems="center"
          key={clone[i].id}
        >
          <Box flex={1}>
            <Input
              placeholder="Даваа - Баасан: Амарна эсвэл Даваа - Баасан: 09:00-18:00 гэх мэт"
              value={clone[i].value}
              onChange={onChangeValue}
            />
          </Box>
          <Box ml={`${theme.spacing.l}em`} flex={1}>
            <Button
              title="Хасах"
              onClick={onClick}
              bgColor={theme.colors.error}
              textColor={theme.palettes.white}
            />
          </Box>
        </Box>
      );
    }

    return data;
  };

  return (
    <>
      <Header title={state ? state.name : "Салбар нэмэх"} />
      <Box px={`${theme.spacing.l}em`}>
        <InputImage
          upload={image.upload}
          img={image.image?.path}
          errorMsg={image.errorMsg}
        />
        <Box mt={`${theme.spacing.l}em`}>
          <Input
            placeholder="Нэр"
            label="Нэр"
            required
            defaultValue={values.name}
            error={touched.name && errors.name !== undefined}
            onChange={handleChange("name")}
            onBlur={handleBlur("name")}
          />
        </Box>
        <Box display="flex" mt={`${theme.spacing.l}em`}>
          <Input
            placeholder="8888-8888, 9999-9999"
            label="Утас"
            defaultValue={values.phone}
            onChange={handleChange("phone")}
          />
          <Box ml={`${theme.spacing.l}em`} flex={1}>
            <Input
              defaultValue={values.facebook}
              placeholder="Фэйсбүүк"
              label="Фэйсбүүк"
              onChange={handleChange("facebook")}
            />
          </Box>
          <Box ml={`${theme.spacing.l}em`} flex={1}>
            <Input
              defaultValue={values.web}
              placeholder="Вэб"
              label="Вэб"
              onChange={handleChange("web")}
            />
          </Box>
        </Box>
        <Box mt={`${theme.spacing.l}em`}>
          <Input
            defaultValue={values.index}
            placeholder="Эрэмбэ"
            label="Эрэмбэ(Жагсаалтад эрэмбэлэгдэх байршил)"
            type="number"
            onChange={handleChange("index")}
          />
        </Box>
        <Box mt={`${theme.spacing.l}em`}>
          <TextField
            defaultValue={values.address}
            placeholder="Хаяг"
            label="Хаяг"
            onChange={handleChange("address")}
          />
        </Box>
        <Box mt={`${theme.spacing.l}em`}>
          <Input
            defaultValue={values.youtube}
            placeholder="Зөвхөн арын хэдэн дугаарыг оруулна уу! Жишээ нь tTOD6xb05mI"
            label="Youtube"
            onChange={handleChange("youtube")}
          />
        </Box>
        <Box display="flex" mt={`${theme.spacing.l}em`}>
          <Select
            value={selectedProvince}
            options={provinces}
            label="Аймаг/Хот"
            placeholder="Аймаг/Хот"
            onChange={changeSelectedProvince}
          />
          <Box ml={`${theme.spacing.l}em`} flex={1}>
            <Select
              value={selectedDistrict}
              options={districts}
              label="Сум/Дүүрэг"
              placeholder="Сум/Дүүрэг"
              onChange={changeSelectedDistrict}
            />
          </Box>
        </Box>
        <Box mt={`${theme.spacing.l}em`}>
          <Checkbox
            label="Хөтөлбөр хэрэгжүүлэгч"
            value={isProject}
            onChange={setIsProject}
          />
        </Box>
        <Box mt={`${theme.spacing.l}em`}>
          <Checkbox
            label="Сум хэсэгт харагдах"
            value={isSum}
            onChange={setIsSum}
          />
        </Box>
        <Box
          mt={`${theme.spacing.l}em`}
          mb={`${theme.spacing.xs}em`}
          fontWeight={500}
          color={theme.palettes.black}
        >
          Цагийн хуваарь
        </Box>
        {renderTimeTable()}
        <Box marginTop={`${theme.spacing.l}em`}>
          <Button title="Цагийн хуваарь нэмэх" onClick={addTimeTable} />
        </Box>
        <Box mt={`${theme.spacing.l}em`}>
          <Button
            title={state ? "Засах" : "Нэмэх"}
            onClick={handleSubmit}
            isLoading={isLoading}
            bgColor={theme.colors.main}
            textColor={theme.palettes.white}
          />
        </Box>
      </Box>
      <Modal isVisible={!!errorMsg} hide={hideModal} title="Алдаа">
        <ModalContent>
          <Box display="flex" fontFamily="Roboto" color={theme.colors.error}>
            {errorMsg}
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddOrEditBranch;
